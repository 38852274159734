<script setup>
import { useWhiteStore } from "@/store/whitePages";

const whiteStore = useWhiteStore();

const innerPages = computed(()=>JSON.parse(JSON.stringify(whiteStore.getPagesArray('card'))));

const changeCard = ref(true);
const isDragging = ref(false);
const startMouseX = ref(0);
const startMouseY = ref(0);
const startTouchX = ref(0);
const startTouchY = ref(0);

function nextClick() {
  changeCard.value = !changeCard.value;
}

function touchstart(event) {
  startTouchX.value = event.touches[0].clientX;
  startTouchY.value = event.touches[0].clientY;
  isDragging.value = true;
}

function touchmove(event) {
  if (!isDragging.value) return;
  const moveX = event.touches[0].clientX;
  const moveY = event.touches[0].clientY;

  const diffX = moveX - startTouchX.value;
  const diffY = moveY - startTouchY.value;

  if (Math.abs(diffX) > Math.abs(diffY)) {
    changeCard.value = !changeCard.value;
    isDragging.value = false;
  }
}

function touchend() {
  isDragging.value = false;
}

function handleMouseDown(event) {
  event.preventDefault();
  startMouseX.value = event.clientX;
  startMouseY.value = event.clientY;
  isDragging.value = true;
}

function handleMouseMove(event) {
  if (!isDragging.value) return;

  const moveX = event.clientX;
  const moveY = event.clientY;

  const diffX = moveX - startMouseX.value;
  const diffY = moveY - startMouseY.value;

  if (Math.abs(diffX) > Math.abs(diffY)) {
    changeCard.value = !changeCard.value;
    isDragging.value = false;
  }
}
</script>

<template>
  <div>
    <section class="home-goldcard" id="goldcard">
      <p class="text-title" data-anim="fadeIn" data-anim-delay="0">
        {{ $t("homepage.The_Gold_Card") }}
      </p>
      <div class="goldcard-first">
        <p class="text-one" data-anim="fadeIn" data-anim-delay=".4">
          {{ $t("homepage.Gold_Card_text_one") }}
        </p>
        <p class="text-two" data-anim="fadeIn" data-anim-delay=".4">
          {{ $t("homepage.Gold_Card_text_two") }}
        </p>
        <p class="text-three" data-anim="fadeIn" data-anim-delay=".4">
          {{ $t("homepage.Gold_Card_text_three") }}
        </p>
      </div>
      <div class="goldcard-img" data-anim="fadeIn" data-anim-delay="0">
        <div
          class="goldcard-img-big"
          @touchstart.passive="touchstart"
          @touchmove.passive="touchmove"
          @touchend.passive="touchend"
          @mousedown="handleMouseDown"
          @mousemove="handleMouseMove"
          @mouseup="touchend"
        >
          <NuxtImg
            src="/image/main/home/gold-card_new.png"
            format="webp"
            alt="gold card"
            :style="{
              opacity: changeCard ? 1 : 0,
              transform: changeCard ? 'translate(0, 0)' : 'translate(-100%, 0)',
            }"
          />
          <NuxtImg
            src="/image/main/home/gold-card-black_new.png"
            format="webp"
            alt="black card"
            :style="{
              opacity: changeCard ? 0 : 1,
              transform: changeCard ? 'translate(0, 0)' : 'translate(-100%, 0)',
            }"
          />
        </div>
        <div class="goldcard-img-small" @click="nextClick">
          <NuxtImg
            src="/image/main/home/gold-card-black_new.png"
            format="webp"
            alt="black card"
            :style="{
              opacity: changeCard ? 1 : 0,
              transform: changeCard ? 'translate(0, 0)' : 'translate(-100%, 0)',
            }"
          />
          <NuxtImg
            src="/image/main/home/gold-card_new.png"
            alt="gold card"
            format="webp"
            :style="{
              opacity: changeCard ? 0 : 1,
              transform: changeCard ? 'translate(0, 0)' : 'translate(-100%, 0)',
            }"
          />
        </div>
      </div>
      <p class="text-feature">{{ $t("Features") }}</p>

      <div class="goldcard-second">
        <div class="card-item" v-for="item in innerPages" :key="item.title">
          <p>{{ $t(item.title) }}</p>
          <div class="card-item-dot align-items-center" v-if="item.img1">
            <NuxtImg class="ellipse-img" :src="item.img1" alt="ellipse" />
            <NuxtImg class="ellipse-img" :src="item.img2" alt="ellipse" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
.home-goldcard {
  margin-top: 160px;
  padding-bottom: 160px;
  display: flex;
  flex-direction: column;

  .text-title {
    font-weight: 400;
    font-size: 180px;
    line-height: 137px;
    color: #ffffff;
    margin-left: 56px;
    letter-spacing: -0.04em;
  }
  .goldcard-first {
    margin: 80px 60px 0px 60px;
    padding: 70px 0px 0px 0px;
    border-top: solid 2px #ffffff;
    display: grid;
    grid-template-areas:
      "one two three"
      "none none four";
    grid-template-columns: 25% 25% 50%;
  }

  .text-one {
    grid-area: one;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #a0a0a0;
    letter-spacing: -0.02em;

    max-width: 185px;
  }
  .text-two {
    grid-area: two;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #a0a0a0;
    letter-spacing: -0.02em;

    max-width: 185px;
  }
  .text-three {
    grid-area: three;
    font-weight: 500;
    font-size: 80px;
    line-height: 80px;
    color: #ffffff;
    letter-spacing: -0.02em;

    max-width: 680px;
  }
  .mastercard {
    grid-area: four;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-top: 56px;

    .card-img {
      width: 37.8px;
      height: 24px;
    }
    .card-text {
      margin-left: 12px;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      color: #a0a0a0;
      letter-spacing: -0.02em;
    }
  }
  .card-mobile-text {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #a0a0a0;
    letter-spacing: -0.02em;

    display: none;
    align-self: center;
  }
  .goldcard-img {
    margin-top: 160px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    .goldcard-img-big {
      width: 30%;
      display: flex;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        align-self: center;
        object-fit: contain;
        transition: all 0.7s;
        flex: 1;
      }
    }
    .goldcard-img-small {
      width: 150px;
      height: 95.6px;
      position: absolute;
      top: 0;
      right: calc(28%);
      transform: translateX(50%);
      display: flex;
      cursor: pointer;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        align-self: center;
        object-fit: contain;
        transition: all 0.7s;
      }
    }
  }
  .text-feature {
    font-weight: 700;
    font-size: 14px;
    line-height: 32px;
    color: #ffffff;
    letter-spacing: -0.02em;

    margin-top: 160px;
    align-self: center;
  }
  .goldcard-second {
    display: flex;
    flex-wrap: wrap;
    margin: 0 60px;
    margin-top: 60px;
    border-bottom: solid 2px #ffffff;

    .card-item {
      font-weight: 400;
      font-size: 48px;
      line-height: 120px;
      color: #ffffff;
      letter-spacing: -0.02em;

      border-top: solid 2px #ffffff;
      height: 120px;
      flex: 50%;

      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .card-item-dot {
        display: flex;
        align-items: center;
        .ellipse-img {
          width: 20px;
          height: 20px;
          margin-left: 10px;
        }
      }
    }
  }
}

@media (max-width: 1599px) {
  .home-goldcard {
    margin-top: 140px;
    padding-bottom: 140px;

    .text-title {
      font-size: 140px;
      line-height: 112px;
      margin-left: 57px;
    }
    .goldcard-first {
      margin: 40px 60px 0px 60px;
      padding: 60px 0px 0px 0px;
    }

    .text-one {
      max-width: 145px;
    }
    .text-two {
      max-width: 145px;
    }
    .text-three {
      font-size: 56px;
      line-height: 64px;

      max-width: 570px;
    }
    .mastercard {
      margin-top: 40px;
    }
    .goldcard-img {
      margin-top: 120px;
      .goldcard-img-small {
        right: calc(26%);
      }
    }
    .text-feature {
      margin-top: 140px;
    }
    .goldcard-second {
      margin-top: 40px;

      .card-item {
        font-size: 36px;
        line-height: 100px;

        height: 100px;

        .card-item-dot {
          .ellipse-img {
            margin-left: 6px;
          }
        }
      }
    }
  }
}
@media (max-width: 1279px) {
  .home-goldcard {
    margin-top: 120px;
    padding-bottom: 120px;

    .text-title {
      font-size: 120px;
      line-height: 120px;
      margin-left: 40px;
    }
    .goldcard-first {
      margin: 30px 40px 0px 40px;
      padding: 60px 0px 0px 0px;
    }

    .text-one {
      max-width: 120px;
    }
    .text-two {
      max-width: 120px;
    }
    .text-three {
      font-size: 56px;
      line-height: 60px;

      max-width: 470px;
    }
    .mastercard {
      margin-top: 30px;
    }
    .goldcard-img {
      .goldcard-img-small {
        right: calc(24%);
      }
    }
    .text-feature {
      margin-top: 120px;
    }
    .goldcard-second {
      margin: 0 40px;
      margin-top: 20px;

      .card-item {
        font-size: 36px;
        line-height: 100px;

        height: 100px;

      }
    }
  }
}

@media (max-width: 1023px) {
  .home-goldcard {
    margin-top: 80px;

    .text-title {
      font-size: 100px;
      line-height: 100px;
      margin-left: 45px;
    }
    .goldcard-first {
      margin: 30px 45px 0px 45px;
      padding: 60px 0px 0px 0px;
      grid-template-areas:
        "three three"
        "one two";
      grid-template-columns: 50% 50%;
    }

    .text-one {
      max-width: 170px;
      margin-top: 30px;
    }
    .text-two {
      max-width: 170px;
      margin-top: 30px;
    }
    .text-three {
      font-size: 48px;
      line-height: 50px;

      max-width: 1000px;
    }
    .mastercard {
      display: none;
    }
    .goldcard-img {
      margin-top: 60px;
      .goldcard-img-big {
        width: 40%;
      }
      .goldcard-img-small {
        right: calc(18%);
      }
    }
    .text-feature {
      margin-top: 120px;
    }
    .card-mobile-text {
      display: block;
      margin-top: 30px;
    }
    .goldcard-second {
      margin: 0 45px;
      margin-top: 20px;

      .card-item {
        font-size: 28px;
        line-height: 80px;

        height: 80px;

        .ellipse-img {
          width: 10px;
          height: 10px;
          margin-left: 10px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .home-goldcard {
    margin-top: 60px;

    .text-title {
      font-size: 48px;
      line-height: 50px;
      margin-left: 20px;
    }
    .goldcard-first {
      margin: 20px 20px 0px 20px;
      padding: 40px 0px 0px 0px;
      grid-template-areas:
        "three"
        "one"
        "two";
      grid-template-columns: 100%;
    }

    .text-one {
      max-width: 700px;
      margin-top: 30px;
    }
    .text-two {
      max-width: 700px;
      margin-top: 15px;
    }
    .text-three {
      font-size: 24px;
      line-height: 30px;

      max-width: 700px;
    }
    .mastercard {
      display: none;
    }
    .goldcard-img {
      .goldcard-img-big {
        img {
          object-fit: cover;
        }
      }
      .goldcard-img-small {
        right: calc(16%);
        width: 120px;
        height: 76.5px;
      }
    }
    .text-feature {
      margin-top: 60px;
      align-self: center;
      margin-left: 0px;
    }
    .card-mobile-text {
      margin-top: 20px;
    }
    .goldcard-second {
      margin: 0 20px;
      margin-top: 10px;
      .card-item {
        font-size: 20px;
        line-height: 60px;

        height: 60px;
        flex: 100%;
      }
    }
  }
}

@media (max-width: 575px) {
  .home-goldcard {
    .goldcard-img {
      justify-content: start;
      padding-left: 20px;
      .goldcard-img-big {
        width: 55%;
      }
      .goldcard-img-small {
        right: 80px;
        width: 120px;
        height: 76.5px;
      }
    }
  }
}

@media (max-width: 375px) {
  .home-goldcard {
    .goldcard-img {
      .goldcard-img-big {
        width: 60%;
      }
      .goldcard-img-small {
        right: 65px;
        width: 25%;
        height: 58px;
      }
    }
  }
}
</style>
